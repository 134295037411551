import { FC } from 'react';

import List from '@mui/material/List';

import { NavLink } from 'libs/components/nav-link';
import { MenuListItem, StyledNavLink } from './styles';
import { NavWithChild } from './types';

type Props = { category: NavWithChild; totalItems: number };

const CategoryList: FC<Props> = ({ category, totalItems }) => {
  const { title, child, path } = category || {};

  return (
    <List>
      {totalItems > 1 && (
        <NavLink
          href={`/${path ?? ''}`}
          style={{ padding: '1rem 1.5rem', fontWeight: 600 }}
        >
          {title}
        </NavLink>
      )}

      {child.map((sub, i) => (
        <StyledNavLink href={sub.url} key={sub.title + i}>
          <MenuListItem>{sub.title}</MenuListItem>
        </StyledNavLink>
      ))}
    </List>
  );
};

export default CategoryList;
