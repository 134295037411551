import { Fragment } from 'react';

import { Stack } from '@mui/material';

import { SubItems, Title } from './style';

const info = [
  { name: 'Articles', href: '/learn' },
  { name: 'Graphics design', href: '/learn/tutorial/graphic-design' },
  {
    name: 'After effect templates',
    href: '/learn/tutorial/after-effects-templates',
  },
];

const Learn = () => {
  return (
    <Stack alignSelf="start" spacing={1}>
      <Title>Learn</Title>
      {info.map((item, index) => (
        <Fragment key={index}>
          <a href={item.href}>
            <SubItems fontSize={'0.85rem'} fontWeight={400}>
              {item.name}
            </SubItems>
          </a>
        </Fragment>
      ))}
    </Stack>
  );
};

export default Learn;
