import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

import { getIpdataV1 } from 'utils/__api__/ip-api';
import { getPremiumPlan } from 'utils/__api__/pricing';

const usePlanDetails = () => {
  const { data: ipData, isSuccess: ipDataSuccess } = useQuery({
    queryKey: ['data_user_pricing'],
    queryFn: () => getIpdataV1(),
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    onSuccess(data) {
      Cookies.set('session_id', JSON.stringify(data.data));
    },
  });

  useQuery({
    queryKey: ['planDetails', ipData?.data?.currency],
    queryFn: () => getPremiumPlan(ipData?.data?.currency ?? 'USD'),
    enabled: !!ipDataSuccess && !!ipData?.data?.currency,
    onSuccess(data) {
      const planDetails = {
        price: Number(data.price) / 12,
        currency: data.currency,
        symbol: data.symbol,
      };

      Cookies.set('planDetails', JSON.stringify(planDetails));
    },
    onError: () => {
      Cookies.set(
        'planDetails',
        JSON.stringify({ price: 3.99, symbol: '$', currency: 'USD' })
      );
    },
    refetchOnWindowFocus: false,
  });
};

export default usePlanDetails;
