'use client';

import React, { useRef, useState } from 'react';

import Cookies from 'js-cookie';

import { Button, Grid, Typography, styled } from '@mui/material';

import useAuth from 'hooks/useAuth';
import useDialougeToggler from 'hooks/useDialougeToggler';
import { requestor } from 'libs/helpers/api/requestor';
import { setClarityEvent } from 'libs/helpers/set-clarity-events';
import { typography } from 'theme/typography';

const StyledInput = styled('input')(({ theme }) => ({
  type: 'number',
  autoComplete: 'off',
  height: '4rem',
  width: '3.5rem',
  borderColor: theme.palette.primary.main,
  borderWidth: '1px',
  fontSize: '1.75rem',
  textAlign: 'center',
  ':focus': {
    outline: 'none',
  },
  '::-webkit-inner-spin-button': {
    display: 'none',
  },
}));

const Verification = () => {
  const { dispatch } = useAuth();
  const { dispatch: dialogDispatch } = useDialougeToggler();
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const message = 'Invalid OTP!...';

  const [otp, setOTP] = useState({
    firstNo: '',
    secondNo: '',
    thirdNo: '',
    fourthNo: '',
  });

  const firstNoRef: any = useRef(null);
  const secondNoRef: any = useRef(null);
  const thirdNoRef: any = useRef(null);
  const fourthNoRef: any = useRef(null);

  const onChangeHandler = (event: any) => {
    setInvalidOTP(false);
    const { name, value } = event.target;
    const splitedValue = value.split('');

    if (!/^[0-9\s]{0,4}$/.test(value)) {
      return;
    }

    if (value !== '') {
      if (splitedValue.length === 1) {
        setOTP({ ...otp, [name]: splitedValue[0] });
      } else if (splitedValue.length === 4) {
        setOTP({
          firstNo: splitedValue[0],
          secondNo: splitedValue[1],
          thirdNo: splitedValue[2],
          fourthNo: splitedValue[3],
        });
        fourthNoRef.current.focus();

        return;
      } else {
        setOTP({
          ...otp,
          [name]: splitedValue[1],
        });
      }

      if (value !== '') {
        switch (name) {
          case 'firstNo':
            secondNoRef.current.focus();
            break;
          case 'secondNo':
            thirdNoRef.current.focus();
            break;
          case 'thirdNo':
            fourthNoRef.current.focus();
            break;
          default:
            break;
        }
      }
    } else {
      setOTP({ ...otp, [name]: '' });

      if (value === '') {
        switch (name) {
          case 'secondNo':
            firstNoRef.current.focus();
            break;
          case 'thirdNo':
            secondNoRef.current.focus();
            break;
          case 'fourthNo':
            thirdNoRef.current.focus();
            break;
          default:
            break;
        }
      }
    }
  };

  const isOTPComplete = () => {
    return Object.values(otp).every((digit) => digit !== '');
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    if (disabled) {
      return;
    }

    setDisabled(true);
    e.preventDefault();
    const combinedNoOTP: number = Number(
      `${otp.firstNo}${otp.secondNo}${otp.thirdNo}${otp.fourthNo}`
    );
    const { transactionId, email }: any = JSON.parse(
      sessionStorage.getItem('verificationCredentials') as any
    );

    setClarityEvent('verify_otp_clicked', 'confirmed');

    try {
      const result: any = await requestor.post('/users/verify', {
        transactionId: transactionId,
        otp: combinedNoOTP,
        email: email,
      });

      Cookies.set('accesstoken', result.data.token);
      dialogDispatch({
        type: 'TOGGLE',
        payload: {
          toggled: false,
          element: 'VerificationForm',
          closeBtn: false,
        },
      });
      window.location.reload();
    } catch (error: any) {
      setInvalidOTP(true);
      firstNoRef.current.focus();
      setOTP({
        firstNo: '',
        secondNo: '',
        thirdNo: '',
        fourthNo: '',
      });
    } finally {
      setDisabled(false);
    }
  };

  return (
    <form
      name="verificationForm"
      style={{ display: 'grid', placeContent: 'center' }}
      onSubmit={onSubmitHandler}
    >
      <Grid
        container
        spacing={3}
        width={{ md: '22rem', sm: '15rem', xs: '22rem' }}
        justifyContent={'center'}
      >
        <Grid item xs={3}>
          <StyledInput
            ref={firstNoRef}
            name="firstNo"
            value={otp.firstNo}
            onChange={onChangeHandler}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
          />
        </Grid>
        <Grid item xs={3}>
          <StyledInput
            ref={secondNoRef}
            name="secondNo"
            value={otp.secondNo}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid item xs={3}>
          <StyledInput
            ref={thirdNoRef}
            name="thirdNo"
            value={otp.thirdNo}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid item xs={3}>
          <StyledInput
            ref={fourthNoRef}
            name="fourthNo"
            value={otp.fourthNo}
            onChange={onChangeHandler}
          />
        </Grid>
        <Grid item xs={12}>
          {invalidOTP && (
            <Typography
              variant="body1"
              fontFamily={typography.fontFamily}
              fontSize="1rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              textAlign="center"
              color={'#7B7B7B'}
            >
              {message}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            color="primary"
            type="submit"
            disabled={!isOTPComplete() || disabled}
          >
            Verify Code
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Verification;
