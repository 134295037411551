'use client';
import { FC, Fragment } from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import useAuth from 'hooks/useAuth';
import { navLinks } from 'libs/helpers/constants/navbar';
import { MainContainer, NavigationLink, StyledNavLink } from './styles';

interface Props {
  drawerClose: () => void;
}

const Navigation: FC<Props> = ({ drawerClose }) => {
  const pathname = usePathname();
  const { state } = useAuth();

  return (
    <MainContainer>
      <Fragment>
        <Typography p="26px 30px 1rem" color="grey.600" fontSize={18}>
          Menu
        </Typography>

        {navLinks.map(({ title, childrens }) => (
          <NavigationLink
            key={title}
            sx={{
              fontSize: '1rem',
              color: pathname === title ? 'primary.main' : 'grey.600',
            }}
          >
            <Accordion>
              <AccordionSummary>{title}</AccordionSummary>
              {childrens.length > 0 &&
                childrens.map((children) => (
                  <Fragment key={children.title}>
                    <Typography
                      fontSize={'1rem'}
                      fontWeight={'600'}
                      paddingLeft={2}
                      color={'primary.main'}
                    >
                      {children.title}
                    </Typography>
                    {children.child.map((child) => (
                      <AccordionDetails key={child.title}>
                        <StyledNavLink
                          href={child.url}
                          isCurrentPath={
                            pathname ? pathname === child.url : false
                          }
                          sx={{
                            fontSize: '0.9rem',
                          }}
                          onClick={drawerClose}
                        >
                          {child.title}
                        </StyledNavLink>
                      </AccordionDetails>
                    ))}
                  </Fragment>
                ))}
            </Accordion>
          </NavigationLink>
        ))}
        <Link href={'/after-effects-templates'}>
          <NavigationLink
            sx={{
              fontSize: '1rem',
              width: '15rem',
              height: '3rem',
              boxShadow: '1px 1px 1px 1px grey.200',
              paddingLeft: '2.5rem',
            }}
            onClick={drawerClose}
          >
            All Items
          </NavigationLink>
        </Link>
        <Link href={'/pricing'}>
          <NavigationLink
            sx={{
              fontSize: '1rem',
              width: '15rem',
              height: '3rem',
              boxShadow: '1px 1px 1px 1px grey.200',
              paddingLeft: '2.5rem',
            }}
            onClick={drawerClose}
          >
            Pricing
          </NavigationLink>
        </Link>
        {state?.user?.role?.toLowerCase() !== 'author' ? (
          <Link href={'/sell-your-content'}>
            <NavigationLink
              sx={{
                fontSize: '1rem',
                width: '15rem',
                height: '3rem',
                boxShadow: '1px 1px 1px 1px grey.200',
                paddingLeft: '2.5rem',
              }}
              onClick={drawerClose}
            >
              Become an author
            </NavigationLink>
          </Link>
        ) : (
          <Link href={'/author'}>
            <NavigationLink
              sx={{
                fontSize: '1rem',
                width: '15rem',
                height: '3rem',
                boxShadow: '1px 1px 1px 1px grey.200',
                paddingLeft: '2.5rem',
              }}
              onClick={drawerClose}
            >
              View Dashboard
            </NavigationLink>
          </Link>
        )}

        <Link href={'/affiliate'}>
          <NavigationLink
            sx={{
              fontSize: '1rem',
              width: '15rem',
              height: '3rem',
              boxShadow: '1px 1px 1px 1px grey.200',
              paddingLeft: '2.5rem',
            }}
            onClick={drawerClose}
          >
            Become an affiliate
          </NavigationLink>
        </Link>
      </Fragment>
    </MainContainer>
  );
};

export default Navigation;
